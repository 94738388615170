/*

$(document).ready(function(){readFiles('dataBaseTable','contentContainerId')})

*/

function readNews(contentContainerId) {
	$.ajax({
		method: 'POST',
		url: '/php/news-read.php',
		dataType: 'json',
		chache: false,
		success: function (answer) {
			if (answer.status == 'done') {
				renderNewsList(answer.data, contentContainerId)
			}
			else {
				const content = $('#' + contentContainerId)
				content.html('Доступ ко всем новостям пока не доступен. Он будет восстановлен в ближайшее время.')
			}
		}
	})
}

function renderNewsList(news, contentContainerId) {
	const content = $('#' + contentContainerId)
	for (let key in news) {
		let newsItem = $('<a/>', {
			class: 'news__item',
			href: news[key].pagePath,
		})
		let newsImgContainer = $('<div/>', {
			class: 'news__img-container',
		})
		let newsImg = $('<img/>', {
			class: 'news__img',
			src: news[key].imgPath,
			alt: news[key].title,
			width: '100%',
			height: 'auto',
		})
		let newsContent = $('<div/>', {
			class: 'news__content',
		})
		let newsDate = $('<p/>', {
			class: 'news__date',
			text: news[key].date,
		})
		let newsTitle = $('<h4>', {
			class: 'news__title',
			text: news[key].title,
		})
		let newsText = $('<p/>', {
			class: 'news__text',
			html: news[key].text,
		})
		content.append(newsItem)
		$(newsItem).append(newsImgContainer)
		$(newsImgContainer).append(newsImg)
		$(newsItem).append(newsContent)
		$(newsContent).append(newsDate)
		$(newsContent).append(newsTitle)
		$(newsContent).append(newsText)
	}
	Ellipsis({
		className: '.news__title',
		lines: 2,
		responsive: true
	})
	Ellipsis({
		className: '.news__text',
		lines: 3,
		responsive: true
	})
}
